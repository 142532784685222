import React from 'react';
import './App.css';
import { Helmet } from 'react-helmet';


function App() {
  return (
    <div className="App">

<div>
      <Helmet>
        <title>Mosque Management tool | mosquesoft.com</title>
        <meta name="description" content="mosquesoft.com est un ERP pour associations culturelles." />
        <meta name="keywords" content="erp, mosquee, islam, association culturelle, muslim,musulmans" />

        {/* Balises Open Graph pour les réseaux sociaux */}
        <meta property="og:title" content="Titre pour les réseaux sociaux" />
        <meta property="og:description" content="Description pour les réseaux sociaux" />
        <meta property="og:url" content="https://www.mosquesoft.com" />

      </Helmet>
    
    </div>

      <header className="App-header">
        <img src="https://res.cloudinary.com/drk3kgyrq/image/upload/v1726239046/mosquesoft.com/logo_officiel_n8d35e.png" className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
